<template>
  <vx-card class="accordin-card mt-8" id="transactionWidget">
    <div class="vx-card__body bsv p-0">
      <vs-collapse accordion>
        <vs-collapse-item open ref="transactionCollapse">
          <div slot="header">
            <h3 class="font-normal text-lg md:text-xl lg:text-2xl">Transactions</h3>
          </div>
          <div class="ds-wrapper">
            <div class="grid gap-y-1 payment-list" v-if="transactionBind">
              <vs-table :data="paymentTransactions" class="transaction-table">
                <colgroup>
                  <col style="width: 15%;">
                  <col style="width: 20%;">
                  <col style="width: 10%;">
                  <col style="width: 20%;">
                  <col style="width: 25%;">
                  <col style="width: 10%;">
                </colgroup>
                <template v-for="(transaction, index) in paymentTransactions">
                  <vs-tr :key="index">
                    <vs-td :data="transaction.dateFormatted">
                      {{ transaction.dateFormatted }}
                    </vs-td>
                    <vs-td :data="transaction.typeText">
                      <p class="text-body uppercase">{{ transaction.typeText }}</p>
                    </vs-td>
                    <vs-td :data="transaction.amountFormatted" colspan="2">
                      <p class="text-body">{{ transaction.amountFormatted }}</p>
                    </vs-td>
                    <vs-td :data="transaction.statusText" class="transaction-chip">
                      <vs-chip :color="getChipColor(transaction)" size="x-small" variant="outlined">{{ transaction.statusText }}</vs-chip>
                    </vs-td>
                    <vs-td :data="transaction.hasChild" class="transaction-toggle">
                      <div v-if="transaction.hasChild" class="cursor-pointer" @click="transaction.hasChild ? handleDisplayChild(index) : ''">
                        <vs-icon v-if="!transaction.showChild" icon="expand_more" class="text-body cursor-pointer expand"></vs-icon>
                        <vs-icon v-else icon="expand_less" class="text-body cursor-pointer expand"></vs-icon>
                      </div>
                    </vs-td>
                  </vs-tr>
                  <template v-if="transaction.showChild">
                    <template v-if="transaction.linkedTransactions && transaction.linkedTransactions.length">
                      <vs-tr :key="index+0.1" class="transaction-dotted"></vs-tr>
                      <vs-tr class="transaction-expand" :key="linkedKey+0.2" v-for="(linkedTransaction, linkedKey) in transaction.linkedTransactions">
                        <vs-td :data="linkedTransaction.dateFormatted">
                          {{ linkedTransaction.dateFormatted }}
                        </vs-td>
                        <vs-td>
                          <p :class="`text-${getChipColor(linkedTransaction)}-600 text-ancillary-caps uppercase`">{{ linkedTransaction.typeText }}</p>
                          <p class="text-body para-sm-light">{{ linkedTransaction.reference }}</p>
                        </vs-td>
                        <vs-td :data="linkedTransaction.amountFormatted">
                          <p :class="`text-${getChipColor(linkedTransaction)}-600`">{{ linkedTransaction.amountFormatted }}</p>
                        </vs-td>
                        <vs-td></vs-td>
                        <vs-td></vs-td>
                        <vs-td></vs-td>
                      </vs-tr>
                    </template>
                  </template>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </div>
  </vx-card>
</template>
<script>

const amexCard = require("@/assets/images/cards/amex-small.svg");
const dinersClubCard = require("@/assets/images/cards/dinersclub-small.svg");
const directDebitCard = require("@/assets/images/cards/directdebit-small.svg");
const editIcon = require("@/assets/images/edit-pencil.svg");
const masterCard = require("@/assets/images/cards/mastercard-small.svg");
const unionpayCard = require("@/assets/images/cards/unionpay-small.svg");
const visaCard = require("@/assets/images/cards/visa-small.svg");

export default {
  props: {
    transactions: Array,
  },

  data() {
    return {
      amexCard: amexCard,
      dinersClubCard: dinersClubCard,
      directDebitCard: directDebitCard,
      editIcon: editIcon,
      masterCard: masterCard,
      paymentTransactions: [],
      unionpayCard: unionpayCard,
      visaCard: visaCard,
      transactionBind: false,
    };
  },

  methods: {
    getChipColor(transaction) {
      let color = "";

      switch(transaction.status) {
        case "Successful":
        case "Disbursed":
        case "Completed":
          color = "success";
          break;
        case "Processing":
        case "Pending":
        case "Queued":
          color = "primary";
          break;
        case "Scheduled":
          color = "neutral";

          switch(transaction.subType) {
            case "Retry":
              color = "secondary";
              break;
            default:
              color = "neutral";
          }

          break;
        case "Applied":
        case "Failed":
        case "Missed":
          color = "error";
          break;
        default:
          color = "neutral";
          break;
      }

      return color;
    },

    handleDisplayChild(index) {
      for (let i = 0; i < this.paymentTransactions.length; i++) {
        if (index == i) {
          this.paymentTransactions[i].showChild = !this.paymentTransactions[i].showChild;
        } else if (this.paymentTransactions[i].hasChild) {
          this.paymentTransactions[i].showChild = false;
        }
      }
      this.$forceUpdate();
    },

    updateDetails() {
      this.transactionBind = false;
      this.paymentTransactions = JSON.parse(JSON.stringify(this.transactions));
      this.paymentTransactions = this.paymentTransactions.map(item => {
        item.hasChild = item.linkedTransactions && item.linkedTransactions.length;
        item.showChild = false;
        return item;
      });
      this.transactionBind = true;
    }
  },

  mounted() {
    this.updateDetails();
  },

  watch: {
    transactions(val) {
      if (val) {
        this.updateDetails();
        // removed scroll to transactions table FP-2623
      }
    },
  }
};
</script>