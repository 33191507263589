<template>
  <div class="mb-12">
    <h3 class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">Additional funding requests</h3>
    <div class="ds-wrapper">
      <vs-table :data="topUps" class="communication-table stripes">
        <template slot="thead">
          <vs-th width="20%">
            <label class="text-normal">Date requested</label>
          </vs-th>
          <vs-th width="18%">
            <label class="text-normal">Last updated</label>
          </vs-th>
          <vs-th width="25%">
            <label class="text-normal">Amount/service fee</label>
          </vs-th>
          <vs-th width="18%">
            <label class="text-normal">Note</label>
          </vs-th>
          <vs-th width="25%">
            <label class="text-normal">Supporting docs</label>
          </vs-th>
          <vs-th width="5%">
            <label class="text-normal">Status</label>
          </vs-th>
          <vs-th width="5%">
            <label class="text-normal">Action</label>
          </vs-th>
        </template>
        <template v-for="(data, index) in topUps">
          <vs-tr :key="index">
            <vs-td :data="data.createdAt">
              {{ data.createdAt }} <br />
              {{ data.createdAtTime }} <br />
              {{ data.createdByName }} <br />
            </vs-td>
            <vs-td :data="data.updatedAt">
              <template v-if="data.status != 'Submitted'">
                {{ data.updatedAt }}<br />
                {{ data.updatedAtTime }} <br />
                {{ data.actionedByName }} <br />
              </template>
            </vs-td>
            <vs-td :data="data.amount">
              {{ formatCurrency(data.amount) }} / {{ formatCurrency(data.fee) }}
            </vs-td>
            <vs-td :data="data.notes">
              {{ data.notes }}
            </vs-td>
            <vs-td :data="data.attachments">
              <template v-if="data.attachments && data.attachments.length" v-for="(attachment, key) in data.attachments">
                <S3FileGenerator :document="attachment" :key="key" />
              </template>
            </vs-td>
            <vs-td :data="data.status">
              <div class="flex align-middle">
                <vs-chip :color="changeStatusColor(data.status)" size="small">{{ data.status }}</vs-chip>
              </div>
            </vs-td>
            <vs-td>
              <a v-if="data.status == 'Submitted'" @click="handleCancelClick(data._id)">Cancel</a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <vs-popup class="holamundo" title="Cancel additional funding" :active.sync="cancelPopUp">
      <p>You're about to cancel the request for additional funding - are you sure?</p>

      <div class="popup-action-btn-container flex justify-end">
        <vs-button class="mr-5" @click="processCancelTopUp" action-btn size="large">Yes</vs-button>
        <vs-button @click="cancelPopUp = false" action-btn color="danger" size="large">No</vs-button>
      </div>
      </vs-popup>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import S3FileGenerator from "@/views/components/S3FileGenerator";

  export default {
    components: {
      S3FileGenerator
    },

    props: {
      topUps: {
        type:  Array,
        default: []
      }
    },

    data() {
      return {
        cancelPopUp: false,
        topUpId: ""
      }
    },

    methods: {
      ...mapActions("paymentRequest", ["cancelTopUp"]),

      handleCancelClick(topUpId) {
        this.topUpId = topUpId;
        this.cancelPopUp = true;
      },

      async processCancelTopUp() {
        this.$vs.loading();

        await this.cancelTopUp(this.topUpId).then(result => {
          this.$vs.loading.close();
          this.showMessage("Success", "Top up cancelled successfully.", "success", "icon-check-circle");
          this.cancelPopUp = false;
          this.$emit("update");
        }).catch(ex => {
          this.$vs.loading.close();
          this.showMessage("Failed", ex.data.message, "danger", "icon-times-circle");
        });
      },

      changeStatusColor(status) {
        let bgClass = "";

        switch (status) {
          case "Approved":
            bgClass = "success";
            break;

          case "Cancelled":
            bgClass = "secondary-500";
            break;

          case "Declined":
            bgClass = "error";
            break;

          case "Submitted":
            bgClass = "primary";
            break;

          default:
            bgClass = "primary";
            break;
        }

        return bgClass;
      },

      showMessage(title, message, color, icon) {
        this.$vs.notify({
          title: title,
          text: message,
          color: color,
          iconPack: "feather",
          position: "top-right",
          icon: icon,
        });
      },

      addPopUpClass(enabled) {
        if (enabled) {
          document.body.classList.add("custom-popup", "decline-popup");
        } else {
          document.body.classList.remove("custom-popup", "decline-popup");
        }
      },
    },

    watch: {
      cancelPopUp(val) {
        this.addPopUpClass(val);

        if (!val) {
          this.topUpId = "";
        }
      }
    }
  }
</script>

